import {
  createVNode,
  defineAsyncComponent,
  nextTick,
  onUnmounted,
  Ref,
  render,
  watch,
  ref,
  UnwrapRef,
  getCurrentInstance
  , AsyncComponentLoader, ComponentPublicInstance, onBeforeUnmount, onDeactivated
  , VNode
} from 'vue'
import { ElDrawer, ElScrollbar } from 'element-plus'
import JlinkUtils from '@/common/global/JlinkUtils'
import ComponentsException from '@/common/errors/ComponentsException'

interface DeepDrawerOptions<T> {
  // Drawer 如果插入到当前element 中 适配尺寸
  adjustParent?: HTMLElement | Ref<HTMLElement | undefined> | string;
  canScroll?: boolean;
  // 传递数据
  param?: Ref<UnwrapRef<T>>;
  component: AsyncComponentLoader<{ new(): ComponentPublicInstance }>;
  // 是否可以通过按下 ESC 关闭 Drawer
  closeOnPressEscape?: boolean;
  // // 控制是否在关闭 Drawer 之后将子元素全部销毁
  // destroyOnClose?: boolean;
  // 关闭前的回调，会暂停 Drawer 的关闭
  // 是否需要遮罩层
  modal?: boolean;
  // Drawer 打开的方向
  direction?: 'rtl' | 'ltr' | 'ttb' | 'btt';
  margin?: { direction: 'top' | 'bottom' | 'left' | 'right'; offset: ()=>number }[];
  // Drawer 窗体的大小, 当使用 number 类型时, 以像素为单位, 当使用 string 类型时, 请传入 'x%', 否则便会以 number 类型解释
  size?: number | string | 'auto';
  // 关闭前的回调，会暂停 Drawer 的关闭
  bClose?: (done: Function) => void;
  // 遮罩层的自定义类名
  modalClass?: string
  zIndex?: number
}

export default class DeepDrawerInitialize<T> {
  private params: DeepDrawerOptions<T>&{isOpen:Ref<boolean>}
  private adjustEl: HTMLElement | undefined | null
  private uuid = JlinkUtils.random.uuid(8)
  private caclSize: { top: number, left: number, width: number | string, height: number | string } | undefined
  private resizeObserver: ResizeObserver | undefined

  private getAdjustEl (el?: HTMLElement | Ref<HTMLElement | undefined> | string) {
    if (el) {
      const _this = this
      if (typeof el === 'string') {
        this.adjustEl = document.querySelector<HTMLElement>(el)
        _this.registerAfterAdjustElGet()
      } else if (el instanceof HTMLElement) {
        this.adjustEl = el
        _this.registerAfterAdjustElGet()
      } else {
        // 需要验证
        watch(() => el.value, function (value) {
          _this.adjustEl = value
          _this.registerAfterAdjustElGet()
        })
      }
    }
  }

  private registerAfterAdjustElGet () {
    const _this = this
    if (this.adjustEl) {
      this.resizeObserver = new ResizeObserver(function () {
        _this.adjust()
        _this.resize()
      })
      this.resizeObserver.observe(this.adjustEl)
    }
  }

  private resize () {
    if (this.adjustEl) {
      const drawerEl = this.adjustEl.querySelector<HTMLElement>('.el-overlay')
      if (drawerEl && this.caclSize) {
        JlinkUtils.dom.setStyleValue(drawerEl, 'top', `${this.caclSize.top}px`)
        JlinkUtils.dom.setStyleValue(drawerEl, 'left', `${this.caclSize.left}px`)
        JlinkUtils.dom.setStyleValue(drawerEl, 'width', `${this.caclSize.width}px`)
        JlinkUtils.dom.setStyleValue(drawerEl, 'height', `${this.caclSize.height}px`)
        // JlinkUtils.dom.setStyleValue(drawerEl, 'pointerEvents', "fill","important")
      }
    }
  }

  private adjust () {
    if (this.adjustEl) {
      const offset = this.params.margin
      let top: number
      let left: number
      let width: number
      let height: number
      const tt = JlinkUtils.dom.getParentTop(this.adjustEl)
      const ll = JlinkUtils.dom.getParentLeft(this.adjustEl)
      if (offset) {
        top = tt
        left = ll
        width = this.adjustEl.clientWidth
        height = this.adjustEl.clientHeight
        for (const margin of offset) {
          const marginOffset = margin.offset()
          switch (margin.direction) {
            case 'bottom':
              height -= marginOffset
              break
            case 'left':
              left += marginOffset
              width -= marginOffset
              break
            case 'right':
              width -= marginOffset
              break
            case 'top':
              top += marginOffset
              height -= marginOffset
              break
          }
        }

        // switch (offset.direction) {
        //   case 'bottom':
        //     top = tt - offset.offset
        //     left = ll
        //     width = this.adjustEl.clientWidth
        //     height = this.adjustEl.clientHeight - offset.offset
        //     break
        //   case 'left':
        //     top = tt
        //     left = ll + offset.offset
        //     width = this.adjustEl.clientWidth - offset.offset
        //     height = this.adjustEl.clientHeight
        //     break
        //   case 'right':
        //     top = tt
        //     left = ll
        //     width = this.adjustEl.clientWidth - offset.offset
        //     height = this.adjustEl.clientHeight
        //     break
        //   case 'top':
        //     top = tt + offset.offset
        //     left = ll
        //     width = this.adjustEl.clientWidth
        //     height = this.adjustEl.clientHeight - offset.offset
        //     break
        // }
      } else {
        top = tt
        left = ll
        width = this.adjustEl.offsetWidth
        height = this.adjustEl.offsetHeight
      }
      this.caclSize = { top, left, width, height }
    }
  }

  constructor (params: DeepDrawerOptions<T>) {
    this.params = { ...params, isOpen: ref(false) }
    const _this = this
    this.getAdjustEl(this.params.adjustParent)
    onUnmounted(function () {
      _this.resizeObserver?.disconnect()
    })
  }

  private drawer:VNode|undefined

  private async updated () {
    const _this = this
    const container = _this.adjustEl || document.body.querySelector<HTMLElement>('#drawer')
    if (container) {
      let component:VNode|undefined
      if (this.params.isOpen.value) {
        const container = createVNode(defineAsyncComponent(_this.params.component), {
          params: _this.params,
          'onDeepDrawerClose' () {
            _this.params.isOpen.value = false
            _this.updated()
          },
          'DeepDrawerEvents' (events: { type: 'async' | 'event', result: any }) {
            if (events.type === 'async') {
              if (events.result instanceof Error) {
                // self.promiseReject && self.promiseReject(events.result)
              } else {
                // self.promiseResolve && self.promiseResolve(events.result)
              }
              // delete self.promiseReject
              // delete self.promiseResolve
            } else {
              console.error('onDeepDialogEvents type event is not support')
            }
          },
        })
        component = createVNode('div', {
          style: { height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }
        }, [
          _this.params.canScroll ? createVNode(ElScrollbar, {}, container) : container
        ])
      }

      this.drawer = createVNode(ElDrawer, {
        ref: 'drawerRef',
        modelValue: _this.params.isOpen.value,
        'onUpdate:modelValue' (event: boolean) {
          _this.params.isOpen.value = event
          _this.updated()
        },
        id: 'drawer' + _this.uuid,
        appendToBody: !_this.adjustEl,
        beforeClose: _this.bClose.bind(_this),
        closeOnPressEscape: (_this.params.closeOnPressEscape === true),
        destroyOnClose: true,
        modal: (_this.params.modal !== false),
        direction: _this.params.direction || 'ltr',
        showClose: false,
        size: _this.params.size || '40%',
        withHeader: false,
        modalClass: _this.params.modalClass,
        zIndex: _this.params.zIndex
      },
      () => component
      )
      render(this.drawer, container)
      if (_this.adjustEl && _this.params.isOpen.value) {
        _this.resize()
      }
    }
  }

  private bClose (done: Function): void {
    if (this.params?.bClose) {
      this.params.bClose(done)
    } else {
      done()
    }
  }

  isOpen () {
    return this.params.isOpen.value
  }

  open (data?: T | Ref<UnwrapRef<T>>) {
    if (data) {
      // @ts-ignore
      if (data.value) {
        this.params.param = data as Ref<UnwrapRef<T>>
      } else {
        this.params.param = ref(data)
      }
    }
    this.params.isOpen.value = true
    this.updated()
  }

  close () {
    this.params.isOpen.value = false
    this.updated()
  }

  static markAsDrawer<T = undefined> () {
    const instance = getCurrentInstance()
    if (!instance) {
      throw new ComponentsException('util 实例不存在')
    }
    const params = instance.attrs.params as DeepDrawerOptions<T>&{isOpen:Ref<boolean>}
    // onDeactivated(function (){
    //   params.isOpen.value=false
    //   console.log("11111111111",params)
    //
    // })
    return {
      data: params.param as (T extends undefined ? undefined : Ref<UnwrapRef<T>>),
      close: function () {
        instance.emit('DeepDrawerClose')
      },
      setResult (result: any) {
        instance.emit('DeepDrawerEvents', { type: 'async', result })
      },
      events: function (result: any) {
        instance.emit('DeepDrawerEvents', { type: 'event', result })
      }
    }
  }
}
