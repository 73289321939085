import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2c132f62"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "deep-image__placeholder"
};
var _hoisted_2 = {
  key: 0,
  "class": "deep-image__error"
};
var _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, {
    "class": "deep-image",
    ref: "imgRef"
  }), [$setup.loading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "loading", {}, function () {
    return [_createVNode($setup["DeepLoading"], {
      style: {
        "width": "1rem",
        "height": "1rem"
      }
    })];
  }, true)])) : _createCommentVNode("v-if", true), _createVNode(_Transition, {
    mode: "out-in",
    name: "opacity-fade-transform"
  }, {
    "default": _withCtx(function () {
      return [$setup.error && !$setup.loading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, function () {
        return [_createVNode($setup["DeepSvgIcon"], {
          name: "jlink",
          style: {
            "color": "#f2f2f2",
            "width": "2.5rem",
            "height": "2.5rem"
          }
        })];
      }, true)])) : _createCommentVNode("v-if", true)];
    }),
    _: 3 /* FORWARDED */
  }), _createElementVNode("img", _mergeProps(_ctx.$attrs, {
    "class": ["deep-image__inner", {
      'deep-image__inner--center': $setup.alignCenter,
      'deep-image__inner_show': $setup.loaded,
      imageClass: $setup.imageClass
    }],
    src: $setup.showSrc,
    onLoad: $setup.onLoad,
    onError: $setup.onError,
    alt: "",
    ref: "imageRef"
  }), null, 16 /* FULL_PROPS */, _hoisted_3)], 16 /* FULL_PROPS */);
}