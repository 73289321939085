import {
  createVNode,
  render,
  ref,
  Ref,
  UnwrapRef,
  nextTick,
  defineAsyncComponent, getCurrentInstance, CSSProperties
  , AsyncComponentLoader, ComponentPublicInstance
  , VNode
} from 'vue'
import { ElDialog } from 'element-plus'
import ComponentsException from '../../../jlink-web/src/common/errors/ComponentsException'
interface DeepDialogOptions<T> {
  title?: string;
  hideHeader?:boolean
  style?:CSSProperties
  param?: Ref<UnwrapRef<T>>;
  center?: boolean;
  bClose?: (done: Function) => void;
  component: AsyncComponentLoader< { new (): ComponentPublicInstance}>;
}
export default class DeepDialogInitialize<T> {
  private params: DeepDialogOptions<T>
  private promiseResolve?: (value: any) => void
  private promiseReject?: (reason: any) => void
  private isOpen: boolean = false
  constructor (params: DeepDialogOptions<T>) {
    this.params = params
  }

  private updated () {
    const self = this
    let component:VNode|undefined

    if (this.isOpen) {
      component = createVNode(defineAsyncComponent(self.params.component), {
        style: {
          width: '100%',
          height: '100%'
        },
        params: self.params,
        'onDeepDialogClose' () {
          self.isOpen = false
          self.updated()
        },
        'onDeepDialogEvents' (events: { type: 'async' | 'event', result: any }) {
          if (events.type === 'async') {
            if (events.result instanceof Error) {
              self.promiseReject && self.promiseReject(events.result)
            } else {
              self.promiseResolve && self.promiseResolve(events.result)
            }
            delete self.promiseReject
            delete self.promiseResolve
          } else {
            console.error('onDeepDialogEvents type event is not support')
          }
        },
      })
    }

    // 生成 node
    const s = createVNode(ElDialog, {
      title: self.params.title,
      beforeClose: self.bClose.bind(self),
      center: self.params.center || true,
      modelValue: self.isOpen,
      closeOnClickModal: false,
      destroyOnClose: true,
      withHeader: false,
      style: {
        padding: 0,
        ...self.params.style
      },
      'onUpdate:modelValue' (event: boolean) {
        self.isOpen = event
        self.updated()
      }
    }, {
      default: () => component
    })
    const dialogContainer = document.body.querySelector<HTMLElement>('#dialog')
    if (dialogContainer) {
      // 把node渲染成dom
      render(s, dialogContainer)
      // 对dom操作
      if (self.params.hideHeader) {
        nextTick(function () {
          const header = document.querySelector<HTMLElement>('.el-dialog__header')
          const body = document.querySelector<HTMLElement>('.el-dialog__body')
          header && header.remove()
          body && (body.style.padding = '0')
        })
      }
    } else {
      console.log('element group with id dialog is lost in body')
    }
  }

  private bClose (done: Function): void {
    if (this.params?.bClose) {
      this.params.bClose(done)
    } else {
      done()
    }
    if (this.promiseReject) {
      console.warn('this dialog is open as openAsync but not consumption with events onDeepDialogEvents use in dialog component')
    }
  }

  toggle () {
    this.isOpen = !this.isOpen
    this.updated()
  }

  open (data: T | Ref<UnwrapRef<T>>) {
    if (data) {
      // @ts-ignore
      if (data.value) {
        this.params.param = data as Ref<UnwrapRef<T>>
      } else {
        this.params.param = ref(data)
      }
    }
    this.isOpen = true
    this.updated()
  }

  openAsync<R> (data: T | Ref<UnwrapRef<T>>): Promise<R> {
    if (data) {
      // @ts-ignore
      if (data.value) {
        this.params.param = data as Ref<UnwrapRef<T>>
      } else {
        this.params.param = ref(data)
      }
    }
    this.isOpen = true
    this.updated()
    return new Promise<R>((resolve, reject) => {
      this.promiseResolve = resolve
      this.promiseReject = reject
    })
  }

  setTitleJust (title: string): DeepDialogInitialize<T> {
    this.params.title = title
    return this
  }

  static markAsDialog<T=undefined> () {
    const instance = getCurrentInstance()
    if (!instance) {
      throw new ComponentsException('util 实例不存在')
    }
    const params = instance.attrs.params as DeepDialogOptions<T>
    return {
      data: params.param as (T extends undefined?undefined:Ref<UnwrapRef<T>>),
      close: function () {
        instance.emit('DeepDialogClose')
      },
      setResult (result:any) {
        instance.emit('DeepDialogEvents', { type: 'async', result })
      },
      events: function (result:any) {
        instance.emit('DeepDialogEvents', { type: 'event', result })
      }
    }
  }
}
