import { ComponentInternalInstance, createVNode, ref, render, VNode, watch, nextTick } from 'vue'
import { remove } from 'lodash'
import NotifyException from '@/common/errors/NotifyException'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkUi from '@/common/global/JlinkUi'

type DeepOssUploadConfig = {
  showLoading?: boolean
  addType?: 'replace' | 'append'
  accept?: string
  autoUpload?: boolean
  multiple?: boolean,
  validateRex?: RegExp,
  limit?: number,
  basePathGet?: ((file: File) => string);
  bucketGet?: ((file: File) => CommonDirType),
  fileChange?: ((files: File[]) => void),
  start?: () => void
  success?: ((result: { key: string; type: string; size: number, name: string }[]) => void),
  fail?: ((type: 'remote' | 'regex' | 'limit', error: Error) => void)
  complete?: () => void
}

export default class DeepOssUploadInitialize {
  /* 业务变量 */
  // 父节点引用
  targetRef = ref<HTMLElement | { $: ComponentInternalInstance } | undefined>()
  inputNode: VNode | undefined
  multiple: boolean = false
  limit: number
  accept: string | undefined
  successCallback: ((result: { key: string; type: string; size: number, name: string }[]) => void) | undefined
  failCallback: ((type: 'remote' | 'regex' | 'limit', error: Error) => void) | undefined
  fileChange: ((files: File[]) => void) | undefined
  validateRex: RegExp | undefined
  basePathGet: ((file: File) => string) | undefined
  bucketGet: ((file: File) => CommonDirType) | undefined
  start?: () => void
  complete?: () => void
  autoUpload = true
  addType: 'replace' | 'append' = 'replace'
  isAdded = false
  list = ref<File[]>([])
  showLoading: true

  constructor (config: DeepOssUploadConfig) {
    const _this = this
    if (config.multiple !== undefined) {
      this.multiple = config.multiple
    }
    this.successCallback = config.success
    this.limit = config.limit || 1024 * 1024
    this.bucketGet = config.bucketGet
    this.basePathGet = config.basePathGet
    this.failCallback = config.fail
    this.validateRex = config.validateRex

    this.start = config.start
    this.complete = config.complete
    if (config.autoUpload !== undefined) {
      this.autoUpload = config.autoUpload
    }
    this.accept = config.accept
    this.showLoading = config.showLoading || true
    this.addType = config.addType || 'replace'
    this.fileChange = config.fileChange
    // TODO watch 处理
    watch(() => this.targetRef.value, (v) => {
      if (v) {
        if (!_this.isAdded) {
          this.updated()
        }
      } else {
        _this.isAdded = false
      }
    })
  }

  private updated () {
    const _this = this
    if (_this.targetRef.value) {
      let e: HTMLElement
      if (Array.isArray(_this.targetRef.value)) {
        if (_this.targetRef.value.length !== 1) {
          console.error('UploadInitialize must bind with one element')
        }
        e = _this.targetRef.value[0].$el || _this.targetRef.value[0]
      } else {
        // @ts-ignore
        e = _this.targetRef.value.$el || _this.targetRef.value
      }

      e.style.position = 'relative'
      this.inputNode = createVNode('input', {
        style: {
          top: '0',
          left: '0',
          position: 'absolute',
          zIndex: '999',
          width: e.clientWidth ? e.clientWidth + 'px' : '100%',
          height: e.clientHeight ? e.clientHeight + 'px' : '100%',
          opacity: '0'
        } as CSSStyleDeclaration,
        accept: _this.accept,

        type: 'file',
        blur: true,
        multiple: this.multiple,
        onChange: function (e: Event) {
          _this.onChange(e)
        }

      })
      _this.isAdded = true
      nextTick()
        .then(() => {
          render(_this.inputNode!!, e)
        })
    }
  }

  deleteFile (file: File) {
    remove(this.list.value, function (f) {
      return f === file
    })
  }

  private onChange (e: Event) {
    const _this = this
    console.log(e)
    console.log(_this.validateRex || '')

    try {
      // @ts-ignore
      const files = e.target?.files
      if (files) {
        const list: File[] = []
        for (const k of files) {
          const size = k.size
          if (size > _this.limit) {
            _this.failCallback && _this.failCallback('remote', new Error('文件太大,最大允许上传' + _this.limit.asByteSizeFormat(2)))
            return;
          }
          if (_this.validateRex) {
            if (_this.validateRex.test(k.name)) {
              list.push(k)
            } else {
              _this.failCallback && _this.failCallback('regex', new Error('当前选择的文件格式不支持'))
              return
            }
          } else {
            list.push(k)
          }
        }
        if (_this.addType === 'replace') {
          _this.list.value = list
        } else {
          _this.list.value.push(...list)
        }
        _this.fileChange && _this.fileChange(_this.list.value)

        if (_this.autoUpload) {
          _this.start && _this.start()

          if (_this.showLoading) {
            JlinkUi.baseLoading(_this.triggerUpload())
              .then((res) => {
                _this.successCallback && _this.successCallback(res)
              })
              .catch((e) => {
                _this.failCallback && _this.failCallback('remote', e)
              })
              .finally(() => {
                _this.complete && _this.complete()
              })
          } else {
            _this.triggerUpload()
              .then((res) => {
                _this.successCallback && _this.successCallback(res)
              })
              .catch((e) => {
                _this.failCallback && _this.failCallback('remote', e)
              })
              .finally(() => {
                _this.complete && _this.complete()
              })
          }
        }
      }
    } catch (e) {
      _this.failCallback && _this.failCallback('remote', e as Error)
    }
  }

  selectFile () {
    const _this = this
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    if (_this.multiple) {
      input.setAttribute('multiple', 'multiple')
    }
    // input.accept = 'device/*'
    input.addEventListener('change', (e) => {
      _this.onChange(e)
    })
    input.click()
  }

  async triggerUpload (onprogress?: (loaded: number, total: number) => void): Promise<{ key: string; type: string; size: number, name: string }[]> {
    const _this = this
    if (_this.list.value.length === 0) {
      return Promise.resolve([])
    } else {
      if (!_this.basePathGet) {
        _this.failCallback && _this.failCallback('limit', new NotifyException('_this.basePathGet is not defined', 'error'))
      }
      if (!_this.bucketGet) {
        _this.failCallback && _this.failCallback('limit', new NotifyException('_this.bucketGet is not defined', 'error'))
      }
      const onprogressSave: StringKeyRecord<{ total: number, loaded: number }> = {}

      return Promise.all(_this.list.value.map(item => {
        const basePath = (_this.basePathGet && _this.basePathGet(item)) || ''
        const dir = (_this.bucketGet && _this.bucketGet(item)) || ''
        return window.$oss.upload(item, basePath, dir, {
          onprogress: onprogress && function (e) {
            onprogressSave[item.name + item.size + item.type] = e
            let total = 0
            let loaded = 0
            for (const key of Object.keys(onprogressSave)) {
              const save = onprogressSave[key]
              total += save.total
              loaded += save.loaded
            }
            onprogress(loaded, total)
          }
        })
      }))
    }
  }
}
