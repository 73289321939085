import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createBlock as _createBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-18fa44c4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "deep-cell-icon"
};
var _hoisted_2 = {
  key: 0,
  "class": "hot"
};
var _hoisted_3 = {
  "class": "deep-cell-bd"
};
var _hoisted_4 = {
  key: 0,
  "class": "deep-cell-text"
};
var _hoisted_5 = {
  key: 2,
  "class": "deep-cell-desc"
};
var _hoisted_6 = {
  "class": "deep-cell-ft"
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = {
  ref: "contentRef",
  "class": "deep-cell-content-slot",
  style: {
    "height": "auto"
  }
};
var _hoisted_9 = {
  "class": "deep-cell-divider"
};
var _hoisted_10 = {
  key: 1,
  "class": "default"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _mergeProps({
    "class": "deep-cell",
    style: {
      background: $props.backgroundLess ? 'transparent' : '#fff'
    }
  }, _ctx.$attrs), [_createElementVNode("div", {
    "class": _normalizeClass(["deep-cell-header", $props.headerBgColor && !($props.collapse && $setup.dockHeights) ? 'check-header' : '']),
    onClick: $setup.handleTap
  }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "icon", {}, undefined, true), $props.icon ? (_openBlock(), _createElementBlock("span", _hoisted_2)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [$props.title ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.title), 1 /* TEXT */)) : _renderSlot(_ctx.$slots, "title", {
    key: 1,
    "class": "deep-cell-text"
  }, undefined, true), $props.label ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($props.label), 1 /* TEXT */)) : _renderSlot(_ctx.$slots, "label", {
    key: 3,
    "class": "deep-cell-desc"
  }, undefined, true)]), _createElementVNode("div", _hoisted_6, [$props.value ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($props.value), 1 /* TEXT */)) : _renderSlot(_ctx.$slots, "default", {
    key: 1
  }, undefined, true)]), _ctx.$props.isLink ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
    key: 0,
    name: "chevron-right",
    "class": "deep-cell-ri",
    style: _normalizeStyle({
      rotate: $setup.collapsed ? '0deg' : '90deg'
    })
  }, null, 8 /* PROPS */, ["style"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("div", {
    "class": "deep-cell-content",
    style: _normalizeStyle({
      height: $props.collapse && $setup.dockHeights ? $setup.collapsed ? '0px' : $setup.contentHeight + 'px' : 'auto'
    })
  }, [_createElementVNode("div", _hoisted_8, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)], 512 /* NEED_PATCH */)], 4 /* STYLE */), _withDirectives(_createElementVNode("div", _hoisted_9, [_ctx.$slots.divider ? _renderSlot(_ctx.$slots, "divider", {
    key: 0
  }, undefined, true) : (_openBlock(), _createElementBlock("div", _hoisted_10))], 512 /* NEED_PATCH */), [[_vShow, $setup.haveDivider]])], 16 /* FULL_PROPS */);
}