import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_card = _resolveComponent("el-card");
  return _openBlock(), _createBlock(_component_el_card, {
    "class": "el-card",
    shadow: $setup.props.shadow
  }, {
    "default": _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["shadow"]);
}