import { ComponentInternalInstance, reactive, ref, watch, Ref } from 'vue'
import {
  InternalRuleItem,
  Rule,
  RuleItem,
  RuleType,
  SyncValidateResult, ValidateFieldsError,
  ValidateOption,
  Value,
  Values
} from 'async-validator'

import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkTask from '@/common/global/JlinkTask'

// export type Rules<T> = { [K in keyof T]?: RuleMaker< T[K],T>[] | RuleMaker< T[K], T> }

export class RuleMaker<K> implements RuleItem {
  trigger: 'blur' | 'change' = 'change'
  type?: RuleType
  required = true
  pattern?: RegExp | string
  min?: number
  max?: number
  len?: number
  enum?: Array<string | number | boolean | null | undefined>
  whitespace?: boolean
  fields?: Record<string, Rule>
  options?: ValidateOption
  defaultField?: Rule
  transform?: (value: Value) => Value
  message?: string | ((a?: string) => string)
  asyncValidator?: (rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void, source: Values, options: ValidateOption) => void | Promise<void>
  validator?: (rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void, source: Values, options: ValidateOption) => SyncValidateResult | void

  setMax (max: number) {
    this.max = max
    return this
  }

  setMin (min: number) {
    this.min = min
    return this
  }

  setMessage (message: string) {
    this.message = message
    return this
  }

  setValidate (validate: (value: K) => Error | void, doAfter?: () => void) {
    this.validator = function (rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void, source: Values, options: ValidateOption) {
      let error
      try {
        error = validate(value)
      } catch (e) {
        error = e as Error
      }
      if (error) {
        callback(error)
        return false
      } else {
        return true
      }
    }

    doAfter && doAfter()
    return this
  }
}
export type Rules<T> = { [K in keyof T]?: RuleMaker< T[K]>[] | RuleMaker< T[K]> }

interface ElProps<T> {
  /* props */
  // https://element-plus.gitee.io/zh-CN/component/form.html#form-%E5%B1%9E%E6%80%A7
  readonly model?: Ref<T>;
  readonly rules?: Rules<T>
  readonly labelPosition?: 'left' | 'right' | 'top'
  readonly labelWidth?: string | number
  readonly labelSuffix?: string
  readonly inline?: boolean
  readonly statusIcon?: boolean
  readonly showMessage?: boolean
  readonly size?: 'large' | 'default' | 'small'
  readonly disabled?: boolean
  readonly validateOnRuleChange?: boolean
  readonly hideRequiredAsterisk?: boolean
}

interface DeepFormContext<T extends (Record<string, unknown>|undefined)> extends ElProps<T> {
  resetFields: () => void
  validate: (f: any) => unknown
  clearValidate: (props?: string | string[]) => void;
  validateField: (props: string | string[], cb: any) => void;
  scrollToField: (prop: string) => void;
  _: ComponentInternalInstance
  $: ComponentInternalInstance
  $el: HTMLElement
  // 还有其他属性暂时用不到
}

export class DeepElFormInitialize<T extends StringKeyRecord<any>> {
  targetRef = ref<DeepFormContext<T>>()
  config = reactive({})
  rulesMaker?: ((rules: Rules<T>, model:T) => Rules<T>)

  constructor (config: ElProps<T>) {
    // TODO watch 处理
    watch(() => this.targetRef.value, (v) => {
      if (v) {
        Object.keys(config).forEach(i => {
          if (i === 'model') {
            // @ts-ignore
            v.$.props.model = config.model.value || {}
          } else {
            // @ts-ignore
            v.$.props[i] = config[i]
          }
        })
        // TODO watch 处理

        watch(() => config.model?.value, function (m) {
          v.$.props.model = m
        })
        const changed = this.rulesMaker && this.rulesMaker(v.$.props.rules as T, v.$.props.model as T)
        if (changed) {
          v.$.props.rules = changed
        }
      }
    })
  }

  setLabelWidth (width: number) {
    if (this.targetRef.value) {
      this.targetRef.value.$.props.labelWidth = width
    }
    return this
  }

  setRules (rules: Rules<T>) {
    if (this.targetRef.value) {
      this.targetRef.value.$.props.rules = rules
    }
    return this
  }

  setModel (model: T) {
    if (this.targetRef.value) {
      this.targetRef.value.$.props.model = model
    }
    return this
  }

  modifyRules (change: (rules: Rules<T>, model:T) => Rules<T>) {
    this.rulesMaker = change
    return this
  }

  validate (success:(model:T, isValid?: string, invalidFields?: ValidateFieldsError)=>void, fail?:(isValid?: string, invalidFields?: ValidateFieldsError)=>void) {
    const _this = this
    this.targetRef.value?.validate(function (isValid?: string, invalidFields?: ValidateFieldsError) {
      if (isValid) {
        const model = _this.targetRef.value?.$.props.model as T
        if (model) {
          JlinkTask.catchAwait(async function () {
            await success(model, isValid, invalidFields)
          })
        } else {
          JlinkTask.catchAwait(async function () {
            fail && await fail(isValid, invalidFields)
          })
        }
      } else {
        JlinkTask.catchAwait(async function () {
          fail && await fail(isValid, invalidFields)
        })
      }
    })
  }

  clearValidate (filed?:string) {
    this.targetRef.value?.clearValidate(filed)
  }

  validateField (filed:string, cb?: any) {
    this.targetRef.value?.validateField(filed, cb)
  }
}
