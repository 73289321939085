import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1e5f2fca"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "t"
};
var _hoisted_2 = {
  key: 0,
  style: {
    "flex": "1"
  }
};
var _hoisted_3 = {
  "class": "d"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": "deep-row-text",
    style: _normalizeStyle({
      width: $setup.props.span ? 100 / $setup.props.span + '%' : 'auto'
    })
  }, [_renderSlot(_ctx.$slots, "title", {}, function () {
    return [_createElementVNode("div", _hoisted_1, _toDisplayString($setup.props.title), 1 /* TEXT */)];
  }, true), $props.flex ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "desc", {}, function () {
    return [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString($setup.props.desc || '--'), 1 /* TEXT */), _renderSlot(_ctx.$slots, "wrapper", {}, undefined, true)])];
  }, true)], 4 /* STYLE */);
}