import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a22bf4d6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "counter"
};
var _hoisted_2 = ["value", "disabled"];
var _hoisted_3 = {
  key: 0,
  "class": "unit"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _mergeProps({
    "class": "deep-counter-view"
  }, _ctx.$attrs), [_createElementVNode("div", {
    "class": "decrease",
    onClick: $setup.handleDecrease
  }, " -"), _createElementVNode("div", _hoisted_1, [_createElementVNode("input", {
    "class": "input",
    type: "number",
    value: $setup.counterValue,
    onFocus: $setup.handleFocus,
    onBlur: $setup.handleUpdateValue,
    onInput: $setup.limitNumberLength,
    disabled: $setup.props.disabled
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), $props.unit ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.unit), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", {
    "class": "increase",
    onClick: $setup.handleIncrease
  }, " +")], 16 /* FULL_PROPS */);
}