import { openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0172981e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["src"];
var _hoisted_2 = {
  key: 1,
  "class": "auto-fill",
  "aria-hidden": "true"
};
var _hoisted_3 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _mergeProps({
    "class": "deep-svg-icon"
  }, _ctx.$attrs), [$setup.isExternals ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [$setup.colorFullV ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $setup.source,
    "class": "auto-fill",
    draggable: "false",
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_1)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    style: _normalizeStyle($setup.source),
    "class": "svg-external-icon"
  }, null, 4 /* STYLE */))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock("svg", _hoisted_2, [_createElementVNode("use", {
    "xlink:href": $setup.source
  }, null, 8 /* PROPS */, _hoisted_3)]))], 16 /* FULL_PROPS */);
}