import { renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5bf94ce7"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "deep-teleport"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.disabled ? _renderSlot(_ctx.$slots, "default", {
    key: 0
  }, undefined, true) : (_openBlock(), _createBlock(_Teleport, {
    key: 1,
    to: $props.to
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 8 /* PROPS */, ["to"]))]);
}