import "core-js/modules/es.array.reverse.js";
import { createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_button, _mergeProps({
    "class": "deep-button",
    size: _ctx.$props.size,
    type: _ctx.$props.type
  }, _ctx.$attrs, {
    onClick: $setup.buttonClick,
    disabled: $props.disabled,
    loading: $props.loading
  }), {
    "default": _withCtx(function () {
      return [_createCommentVNode("    <DeepSvgIcon :name=\"props.icon\" class=\"icon\" style=\"margin-right: .5rem\" v-if=\"props.icon\"></DeepSvgIcon>"), _createElementVNode("div", {
        "class": "button-content",
        style: _normalizeStyle([{
          "display": "flex",
          "align-items": "center",
          "justify-content": "center"
        }, {
          flexDirection: $setup.reverse ? 'row-reverse' : 'row'
        }]),
        ref: "contentRef"
      }, [$props.icon ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
        key: 0,
        name: $props.icon,
        id: "icon",
        style: _normalizeStyle({
          width: $setup.iconWidth + 'px',
          marginRight: $setup.reverse ? 0 : '4px',
          marginLeft: $setup.reverse ? '4px' : 0
        })
      }, null, 8 /* PROPS */, ["name", "style"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default")], 4 /* STYLE */), _createCommentVNode("    <DeepSvgIcon v-if=\"icon\" :name=\"icon\" class=\"icon\" id=\"icon\" :style=\"{width:iconWidth+'px'}\" ></DeepSvgIcon>"), _createCommentVNode("    <div class=\"desc\"><slot></slot></div>")];
    }),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["size", "type", "disabled", "loading"]);
}