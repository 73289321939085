import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f1b95886"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "share-player"
};
var _hoisted_2 = {
  "class": "content"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    onClick: _withModifiers($setup.handleShare, ["stop"])
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), (_openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [$setup.shareFlag ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    "class": "icons",
    onClick: $setup.handleCopy
  }, [_createVNode($setup["DeepSvgIcon"], {
    "class": "icon",
    name: "infomation",
    onClick: $setup.handleCopy
  }), $setup.copyFlag ? (_openBlock(), _createElementBlock("p", _hoisted_3, "复制成功")) : _createCommentVNode("v-if", true), _createVNode($setup["DeepSvgIcon"], {
    "class": "icon",
    name: "close",
    onClick: $setup.handleClose
  })]), _createElementVNode("img", {
    alt: "",
    src: $setup.src
  }, null, 8 /* PROPS */, _hoisted_4)])])) : _createCommentVNode("v-if", true)]))]);
}