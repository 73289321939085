import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_date_picker = _resolveComponent("el-date-picker");
  return _openBlock(), _createBlock(_component_el_date_picker, {
    placeholder: _ctx.$props.placeholder,
    modelValue: $setup.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.date = $event;
    }),
    type: _ctx.$props.type,
    onChange: $setup.dateChange,
    onFocus: $setup.handleFocus,
    "value-format": "x",
    format: "YYYY/MM/DD",
    editable: false,
    clearable: false,
    disabled: _ctx.$props.disabled,
    disabledDate: _ctx.$props.disabledDate
  }, null, 8 /* PROPS */, ["placeholder", "modelValue", "type", "disabled", "disabledDate"]);
}