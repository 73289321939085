import "core-js/modules/es.date.to-json.js";
import "core-js/modules/es.json.stringify.js";
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9eec521e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["webrtcVideoSize"];
var _hoisted_2 = {
  "class": "placeholder"
};
var _hoisted_3 = {
  controls: "",
  ref: "videoRef",
  style: {
    "width": "100%",
    "height": "100%",
    "object-fit": "cover",
    "background-color": "#000",
    "position": "absolute"
  },
  "webkit-playsinline": "",
  playsinline: "",
  "x5-video-player-type": "h5-page",
  "x5-video-orientation": "landscape"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": "flv-view",
    webrtcVideoSize: JSON.stringify($setup.videoSize)
  }, [_createElementVNode("div", _hoisted_2, [_createVNode(_Transition, {
    name: "opacity-fade-transform",
    mode: "out-in"
  }, {
    "default": _withCtx(function () {
      return [$setup.loading ? (_openBlock(), _createBlock($setup["DeepLoading"], {
        key: 0
      })) : !$setup.isPlaying && $setup.canPlay ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
        key: 1,
        name: "play",
        "class": "icon",
        onClick: $setup.play
      })) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("video", _hoisted_3, null, 512 /* NEED_PATCH */)], 8 /* PROPS */, _hoisted_1);
}