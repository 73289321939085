import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a90f2310"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "deep-joystick",
  ref: "joystickRef"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    "class": _normalizeClass(["ball", {
      animation: $setup.inDragging === false
    }]),
    style: _normalizeStyle({
      left: $setup.left + 'px',
      top: $setup.top + 'px'
    })
  }, null, 6 /* CLASS, STYLE */)], 512 /* NEED_PATCH */);
}