import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-50c4d654"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "edit-input-item"
};
var _hoisted_2 = {
  key: 1,
  "class": "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "prefix", {}, undefined, true), $props.canEdit ? (_openBlock(), _createBlock(_component_el_input, {
    key: 0,
    type: $props.inputType,
    maxlength: $props.maxLength,
    disabled: !$setup.editable,
    "class": "content",
    modelValue: $setup.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.value = $event;
    }),
    placeholder: $props.placeholder
  }, null, 8 /* PROPS */, ["type", "maxlength", "disabled", "modelValue", "placeholder"])) : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.value), 1 /* TEXT */)), $props.canEdit ? (_openBlock(), _createBlock($setup["DeepButton"], {
    key: 2,
    type: "primary",
    onClick: $setup.edit
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.editable ? '提交' : '编辑'), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $setup.editable ? (_openBlock(), _createBlock($setup["DeepButton"], {
    key: 3,
    type: "danger",
    onClick: $setup.cancel
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("取消")];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]);
}