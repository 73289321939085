import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-cbaff972"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "deep-drag-view",
  ref: "touchViewRef"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 512 /* NEED_PATCH */);
}