import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3b996a4e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["aspectValue"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": "deep-aspect-view",
    aspectValue: $setup.aspectValue,
    ref: "viewRef"
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 8 /* PROPS */, _hoisted_1);
}